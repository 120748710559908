/*----------  Global constiables  ----------*/

let wWidth;
let wHeight;

/*----------  Document Ready  ----------*/

$(document).ready(function() {
	wWidth = $(window).width();
	wHeight = $(window).height();

	console.log('Ready!');
	fn_toggle();
	filter_toggle();

	//navbar toggle class
	$(".hamburger").on('click', function(e){
		$(this).toggleClass('is-active');
		$('body').toggleClass('overflow-hidden');
        $(".navbar-wrapper").toggleClass('navbar-responsive');
        $(".navbar-container").addClass("navbar-solid");
        if(!$(this).hasClass('is-active') && $('.navbar-container').hasClass('navbar-absolute')) {
            $(".navbar-container").removeClass("navbar-solid");
		}

		if (wWidth < 992 && $(".navbar-wrapper").hasClass('navbar-responsive')) {
			console.log("Menu open");
			$(".navbar-wrapper").find('.nav-links li').each(function(position){
				$(this).addClass('animate__animated animate__fadeInUp animate__delay-'+position+'s');
			});
		} else {
			$(".navbar-wrapper").find('.nav-links li').removeClass('animate__animated animate__fadeInUp');
		}

	});

	var lastScrollTop = 0;
	$(window).scroll(function() {
		// var aTop = $('.ad').height();
		// if(wWidth < 991.99) {
			if($(this).scrollTop() > 0 && !$('.navbar-container').hasClass('navbar-fixed')) {
				$('.navbar-container').addClass('navbar-fixed');
				// $('body').css('padding-top', $('.navbar-container').outerHeight() + "px");
				// instead of alert you can use to show your ad
				// something like $('#footAd').slideup();
			}
			if($(this).scrollTop() <= 0) {
				$('.navbar-container').removeClass('navbar-fixed');
				// $('body').css('padding-top', 0);
			}

			var st = $(this).scrollTop();
			if($('.navbar-container').hasClass('navbar-fixed') && !$('body').hasClass('overflow-hidden')) {
				if (st > lastScrollTop){
 					$('.navbar-container').css("transform", "translateY(-100%)");
				} else {
					$('.navbar-container').css("transform", "translateY(0%)");
				}
			}
			lastScrollTop = st;
		// }
	});

    init_slider();
    initCouterUp();
	initSmoothScroll();
	fnCookieBar();
});

function filter_toggle()
{
	var target;

	var $toggler = $('[data-filter-toggle]');

	if($toggler.length > 0)
	{
		$toggler.off('click.toggle').on('click.toggle', function()
		{
			target = $(this).data('filter-toggle');
			var $target = $(target).first();

			if($target.length > 0)
			{
				if($target.is(':visible'))
				{
					$target.fadeOut('fast');
					$(this).removeClass('open');
					$('body').removeClass('overflow-hidden');
				}
				else
				{
					$target.fadeIn('fast');
					$(this).addClass('open');
					$('body').addClass('overflow-hidden');
				}
			}

			return false;
		});
	}
}

function fn_toggle()
{
	var target;

	var $toggler = $('[data-fn-toggle]');

	if($toggler.length > 0)
	{
		$toggler.off('click.toggle').on('click.toggle', function()
		{
			target = $(this).data('fn-toggle');
			var $target = $(target).first();

			if($target.length > 0)
			{
				if($target.is(':visible'))
				{
					$target.fadeOut('fast');
					$(this).removeClass('open');
				}
				else
				{
					$target.fadeIn('fast');
					$(this).addClass('open');
				}
			}

			return false;
		});
	}
}

function fnCookieBar() {
	// console.log('Hello');
	console.log(localStorage.getItem('cookieTerms'));
	if(localStorage.getItem('cookieTerms') == 'true') {
		$('#cookieBar').hide();
		// console.log('Hello Condition');
	}
	$('#acceptCookieBtn').on('click', function() {
		localStorage.setItem("cookieTerms", true);
		// console.log('Hello Click');
		$('#cookieBar').slideUp();
	});
}

function fn_toggle_slide()
{
	var target;

	var $toggler = $('[data-fn-toggleslide]');

	if($toggler.length > 0)
	{
		$toggler.off('click.toggle').on('click.toggle', function()
		{
			target = $(this).data('fn-toggleslide');
			var $target = $(target).first();

			if($target.length > 0)
			{
				if($target.is(':visible'))
				{
					$target.hide();
					$(this).removeClass('open');
				}
				else
				{
					$target.show();
					$(this).addClass('open');
				}
			}

			return false;
		});
	}
}

/*----------  Window Events  ----------*/

$(window).on('load resize', function() {
	wWidth = $(window).width();
	wHeight = $(window).height();

	if(wWidth > 575) {
		fn_toggle_slide();
	}
});

$('.preloader-wrapper').closest('body').css('overflow-y', 'hidden');
$(window).on('load', function() {
	TwentyTwenty.init();
	hidePreloader();
});

function hidePreloader() {
	$('.preloader-wrapper').fadeOut('fast', function(){
		$(this).closest('body').css('overflow-y', 'auto');
	});
}

function showPreloader() {
	$('.preloader-wrapper').closest('body').css('overflow-y', 'hidden');
	$('.preloader-wrapper').fadeIn('fast', function(){
		$('.preloader-wrapper').closest('body').css('overflow-y', 'hidden');
	});
}

function initCouterUp() {
    $('.counter').counterUp({
        time: 3500
    });
}

function initSmoothScroll() {
	$('.smoothScroll').on('click', function(event) {
		event.preventDefault();
		$('body, html').animate({
	        scrollTop: $($(this).attr('href')).offset().top// - $('header').outerHeight()
	    }, 1000);
	});
}

function init_slider() {

	// $('input').iCheck({
	// 	checkboxClass: 'icheckbox_minimal-red',
	// 	radioClass: 'iradio_minimal-red',
	// });

	const $main_slider = $(".main-slider");
	const $mainLinks = $('.main-links');

	$main_slider.slick({
		slidesToShow: 1,
		arrows: false,
        infinite: false,
        autoplay: true,
        autoplaySpeed: 3000,
		fade: true,

		responsive: [{
			breakpoint: 576,
			settings: {
				asNavFor: $mainLinks
			}
		}]
	});

	if(wWidth <= 576) {
		$mainLinks.slick({
			arrows: false,
			infinite: false,
			slidesToShow: 1,
			centerMode: true,
			centerPadding: 0,
			variableWidth: true,
			asNavFor: $main_slider,
		});
	} else {
		$('li', $mainLinks).first().addClass('active');

		$main_slider.off('afterChange.main').on('afterChange.main', function(e, slick, currentSlide) {
			const $links = $('li', $mainLinks);
			$links.removeClass('active');
			$links.eq(currentSlide).addClass('active');
		});

	}


	$('a', $mainLinks).on('click', function (e) {
		e.preventDefault();

		const $this = $(this);
		const index = $this.data('index');

		$main_slider.slick('slickGoTo', index);
	});

	const $video_slider = $(".slider-inner-content-container");
	const $video_media_slider = $(".slider-inner-media-container");
	const $videoSliderLinks = $('.cntVideoSlider .slider-links');

	$video_slider.slick({
		slidesToShow: 1,
        arrows: false,
        fade:true,
        asNavFor: '.videoSliderSync',
		infinite: false,
		adaptiveHeight: true,
    });

	$video_media_slider.slick({
		slidesToShow: 1,
        arrows: false,
        fade:true,
        asNavFor: '.videoSliderSync',
		infinite: false,
		swipe: false,
		adaptiveHeight: true,
	});

	if(wWidth <= 576) {
		$videoSliderLinks.addClass('videoSliderSync').slick({
			arrows: false,
			infinite: false,
			slidesToShow: 1,
			centerMode: true,
			centerPadding: 0,
			variableWidth: true,
			asNavFor: '.videoSliderSync',
		});
	} else {
		$('li', $videoSliderLinks).first().addClass('active');
		$video_slider.on('afterChange', function(event, slick, currentSlide){
			const $links = $('li', $videoSliderLinks);

			$links.removeClass('active');
			$links.eq(currentSlide).addClass('active');
		});
	}

	$('a', $videoSliderLinks).on('click', function(e){
		e.preventDefault();

		const $this = $(this);
		const index = $this.data('index');

		$video_slider.slick('slickGoTo', index);
	});

	$(".testimonial-inner-wrapper").slick({
		arrows: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: 3000,
        adaptiveHeight: true,
		appendDots: '.slick-slider-dots-container',

		customPaging: function() { return `<span class='slick-dots-circle'></span>`; },

		responsive: [{
			breakpoint: 576,
			settings: {
				appendDots: '.testimonial-inner-wrapper'
			}
		}]
	});

	// $('#contactForm').modal('show');
	// $('#propertyForm').modal('show');
	// $('#registrationForm').modal('show');
}

const TwentyTwenty = {
	init: function() {
		const $cnt = $('[data-twentytwenty]');

		$cnt.each(function(i, cnt) {
			const offset = $(cnt).data('offset') || 0.7
			$(cnt).twentytwenty({
				no_overlay: true,
				before_label: 'After',
				after_label: 'Before',
				default_offset_pct: offset,
			});
		});
	}
}
